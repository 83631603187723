import { Row, TableProps } from "react-table";
import { AddQuestionArgs } from "../../helpers/types";

export const TableColumns = (quiz: { currentActive: string; quizes: { [key: string]: AddQuestionArgs[]}, quizzes: string[] }) => {
    const COLUMNS = [
        {
            Header: "No.",
            accessor: "colNumber",
            width: "1%",
            Cell: (TableInfo: TableProps) => {
                const nmb = TableInfo.row.index + 1;
                return nmb;
            },
        },
        {
            Header: "Create Time",
            sortType: "datetime",
            accessor: "creationTime",
            Cell: (TableInfo: TableProps) => {
                const dateString = TableInfo.data[TableInfo.row.index].creationTime.toLocaleString();
                return dateString;
            },
        },
        {
            Header: "Last Action",
            accessor: "updateTime",
            sortType: (rowA: Row, rowB: Row, columnId: number) => {
                if (rowA.values[columnId] > rowB.values[columnId]) return 1;
                if (rowB.values[columnId] > rowA.values[columnId]) return -1;
                return 0;
            },
            Cell: (TableInfo: TableProps) => {
                const dateString = TableInfo.data[TableInfo.row.index].updateTime ? TableInfo.data[TableInfo.row.index].updateTime.toLocaleString() : "";
                return dateString;
            },
        },
        {
            Header: "First Name",
            accessor: "firstName",
            Cell: (TableInfo: TableProps) => {
                return TableInfo.data[TableInfo.row.index].firstName ? (
                    <a className="link-more link-more--alt" href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
                        {TableInfo.data[TableInfo.row.index].firstName}
                    </a>
                ) : (
                    "N/A"
                );
            },
        },
        {
            Header: "Last Name",
            accessor: "lastName",
            Cell: (TableInfo: TableProps) => {
                return TableInfo.data[TableInfo.row.index].lastName ? (
                    <a className="link-more link-more--alt" href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
                        {TableInfo.data[TableInfo.row.index].lastName}
                    </a>
                ) : (
                    "N/A"
                );
            },
        },
        {
            Header: "Email Address",
            accessor: "email",
            Cell: (TableInfo: TableProps) => {
                return TableInfo.data[TableInfo.row.index].email ? (
                    <a className="link-more link-more--alt" href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
                        {TableInfo.data[TableInfo.row.index].email}
                    </a>
                ) : (
                    "N/A"
                );
            },
        },
        {
            Header: "Province",
            accessor: "province",
            Cell: (TableInfo: TableProps) => {
                return TableInfo.data[TableInfo.row.index].province ? (
                    <a className="link-more link-more--alt" href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
                        {TableInfo.data[TableInfo.row.index].province}
                    </a>
                ) : (
                    "N/A"
                );
            },
        },
        {
            Header: "Scene+ number",
            accessor: "sceneplus",
        },
        {
            Header: "Phone",
            accessor: "phone",
            Cell: (TableInfo: TableProps) => {
                return TableInfo.data[TableInfo.row.index].phone ? (
                    <a className="link-more link-more--alt" href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
                        {TableInfo.data[TableInfo.row.index].phone}
                    </a>
                ) : (
                    "N/A"
                );
            },
        },

        {
            Header: "Language",
            accessor: "preferredLanguage",
        },
        {
            Header: "Store Banner",
            accessor: "banner",
        },
        {
            Header: "EmployeeNo",
            accessor: "employeeNo",
        },
        {
            Header: "Location Type",
            accessor: "locationType",
        },
        {
            Header: "Store Type",
            accessor: "storeType",
        },
        {
            Header: "Location",
            accessor: "location",
        },       
        {
            Header: "StoreNo",
            accessor: "storeNo",
        },
        {
            Header: "Store Manager",
            accessor: "manager",
        },
        ...quiz.quizzes.map(q=>{   
            return {
                Header: `Trivia ${quiz.quizes[q][0].quizName}`,
                accessor: quiz.quizes[q][0].quiz,
                Cell: (TableInfo: TableProps) => {                    
                    return TableInfo.data[TableInfo.row.index].trivia && TableInfo.data[TableInfo.row.index].trivia[q] ? `${TableInfo.data[TableInfo.row.index].trivia[q]}`                     
                    : "N/A";
                },
            }
        })

        // {
        //     Header: "Scene Number",
        //     accessor: "sceneNumber",
        //     Cell: (TableInfo: TableProps) => {
        //         return TableInfo.data[TableInfo.row.index].sceneNumber ? (
        //             <a className="link-more link-more--alt" href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
        //                 {TableInfo.data[TableInfo.row.index].sceneNumber}
        //             </a>
        //         ) : (
        //             "N/A"
        //         );
        //     },
        // },

        // {
        //     Header: "Terms/Rules",
        //     accessor: "rules",
        //     Cell: (TableInfo: TableProps) => {
        //         return (
        //             <a className="link-more link-more--alt" href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
        //                 {TableInfo.data[TableInfo.row.index].rules}
        //             </a>
        //         );
        //     },
        // },

        // {
        //     Header: "Opt-in",
        //     accessor: "optin",
        //     Cell: (TableInfo: TableProps) => {
        //         return (
        //             <a className="link-more link-more--alt" href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
        //                 {TableInfo.data[TableInfo.row.index].optin1}
        //             </a>
        //         );
        //     },
        // },
    ];
    return COLUMNS;
};
