import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import * as MainDbReturnTypes from "@sprycore/main-db-types/ReturnTypes";
import { PageLoader, Table } from "../../Components";
import { TableColumns } from "./ParticipantCol";
import { campaignClient } from "../../api";
import { CSVLink } from "react-csv";
import axios from "axios";
import { AddQuestionArgs } from "../../helpers/types";
import { merge } from "lodash";

function Participants() {

    const { isLoading: isLoadingParticipants, data: participants } = useQuery("getParticipants", async () => {
        const res: { participants: MainDbReturnTypes.Participant[], result: {participants: MainDbReturnTypes.Participant[]}, largeResultUrl: string } = await campaignClient.call("getParticipants", {});
        let participants;
        if (res.participants) {
            participants = res.participants || res.result.participants;
        }
        if (res.result) {
            participants = res.result.participants;
        }

        if (res.largeResultUrl) {
            const result = await axios(res.largeResultUrl);
            const response: MainDbReturnTypes.Participant[] = await result.data.result.participants;
            participants = response;
        }
        return participants;
    });

    const {
        isLoading,
        data: quiz,      
    } = useQuery("getQuiz", async () => {
        const res: { currentActive: string; quizes: { [key: string]: AddQuestionArgs[] } } = await campaignClient.call("getQuiz", {});
        return { ...res, quizzes: Object.keys(res.quizes).length > 0 ? Object.keys(res.quizes) : [] };
    });

    const [filterTable, setFilterTable] = useState("");

    const data = useMemo(() => {
        if (participants && participants.length > 0) {
            
            return participants.map(participant => {
                return {
                    firstName: participant.firstName,
                    lastName: participant.lastName,
                    email: participant.email,
                    postal: participant.postal,
                    phone: participant.phone,
                    creationTime: new Date(participant.creationTime),
                    updateTime: new Date(participant.updateTime),
                    sessionKey: participant.sessionKey,
                    province: participant.province,
                    banner: participant.metadata.banner ? participant.metadata.banner : "N/A",
                    locationType: participant.metadata.locationType ? participant.metadata.locationType : "N/A",
                    storeType: participant.metadata?.storeType ? participant.metadata?.storeType : "N/A",
                    preferredLanguage: participant.preferredLanguage ? participant.preferredLanguage : "NA",
                    employeeNo: participant.metadata?.employeeNo ? participant.metadata.employeeNo : "NA",
                    location: participant.metadata?.location ? participant.metadata?.location : "NA",
                    manager: participant.metadata.manager ? participant.metadata.manager : "NA",
                    trivia: participant.metadata?.scores ? participant.metadata?.scores : "",
                    storeNo: participant.metadata?.storeNo ? participant.metadata?.storeNo : "NA",
                    sceneplus: participant.metadata?.sceneplus ? participant.metadata?.sceneplus : "NA",

                };
            });
        }
    }, [participants]);

    const reportData = useMemo(() => {
        if (data && quiz) {
            return data.map((rp) => {
                
                const triviaScore = quiz.quizzes.map(q=>{
                    return {
                        [`Trivia ${quiz.quizes[q][0].quizName}`]: `${rp.trivia && rp.trivia[q] ? rp.trivia[q] : "N/A"}`
                    }
                })               
                return merge({
                    "Creation Time": rp.creationTime.toLocaleString(),
                    "Last Action": rp.updateTime.toLocaleString(),
                    "First Name": rp.firstName,
                    "Last Name": rp.lastName,
                    "Email": rp.email, 
                    "Province": rp.province,                   
                    "Scene+ number": rp.sceneplus,
                    "Language": rp.preferredLanguage,
                    "Store Banner": rp.banner,
                    "EmployeeNo": rp.employeeNo,
                    "Location Type": rp.locationType,
                    "Store Type": rp.storeType,
                    "Location": rp.location,
                    "Manager": rp.manager,                   
                    "storeNo": rp.storeNo,

                    // Rules: rp.rules,

                    // "Scene Number": rp.sceneNumber ? "'" + rp.sceneNumber + "'" : "N/A",
                }, ...triviaScore);
            });
        }
    }, [data, quiz]);

    const columns = useMemo(()=>{
        if (quiz){
            return TableColumns(quiz)
        }
        
    }, [quiz]);


    if (isLoadingParticipants || isLoading || !columns) {
        return <PageLoader />;
    }
    return !isLoadingParticipants && participants && participants.length > 0 ? (
        <>
            <div className="main__head">
                <h2 className="main__title">Participants</h2>
                <div className="main__actions">
                    <div className="search search--alt">
                        <div className="search__row search__row--input">
                            <CSVLink
                                filename={`Campaign-Participants-Report-${new Date().toLocaleDateString()}`}
                                className="btn btn--small btn--mobile-small"
                                data={reportData ? reportData : ""}
                                asyncOnClick={true}
                                target="_blank">
                                Download Report
                            </CSVLink>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main__body">
                <div className="tabs js-tabs">
                    <div className="search search--alt search--medium main__search">
                        <div className="search__row">
                            <label htmlFor="q" className="hidden">
                                Search
                            </label>
                            <input
                                type="search"
                                name="q"
                                id="q"
                                value={filterTable}
                                placeholder="Search"
                                className="search__field"
                                onChange={(e) => setFilterTable(e.target.value)}></input>
                            <button type="submit" className="search__btn">
                                <svg className="ico-search">
                                    <image xlinkHref="/assets/images/svg/ico-search.svg"></image>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tabs__body mt-5">
                <div className="table table--alt table--tabs table--big">
                    <Table columns={columns} data={data ? data : []} tablePageSize={15} filterValue={filterTable} />
                </div>
            </div>
        </>
    ) : (
        <p>Currently there are no any participants to display.</p>
    );
}

export default Participants;
